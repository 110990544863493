<template>
  <div class="wrapper">
    <div style="width: 150px; height: 200px"></div>
    <div class="main main-raised">
      <div class="section section-contact">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100 ml-auto">
              <h2 class="title mt-0">ติดต่อทีมงาน Auto Rebalance</h2>
              <p class="description">
                ติดต่อทีมงาน Auto Rebalance เพื่อสอบถามข้อมูล
                เราจะทำการติดต่อกลับท่านโดยเร็วที่สุด
                <br />
                <br />
              </p>
              <img
                src="https://www.clearswift.com/sites/default/files/2021-09/hs-contact-us-figure.png"
                style="
                  width: 90%;
                  border-style: solid;
                  border-width: medium;
                  border-radius: 10px;
                  margin-top: 10px;
                "
              />
              <!-- <form>
                <md-field>
                  <label>Your Name</label>
                  <md-input v-model="name" type="text" />
                </md-field>

                <md-field>
                  <label>Phone</label>
                  <md-input v-model="phone" type="number" />
                </md-field>
                <md-field>
                  <label>Your message</label>
                  <md-textarea v-model="textarea" />
                </md-field>
                <div class="submit text-center mt-3">
                  <md-button class="md-primary md-round">
                    Contact Us
                  </md-button>
                </div>
              </form> -->
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100 ml-auto">
              <info-areas
                class="pt-0"
                info-horizontal
                icon-color="primary"
                icon="email"
              >
                <h4 slot="title" class="info-title">ขอข้อมูลเพื่มเติม</h4>
                <div slot="content" class="description">
                  <p>
                    mail : aiautorebalance@gmail.com <br />
                    089-1769303 <br />
                    Facebook | Line
                  </p>
                </div>
              </info-areas>
              <info-areas
                class="pt-0"
                info-horizontal
                icon-color="primary"
                icon="phone"
              >
                <h4 slot="title" class="info-title">โทรสอบถามข้อมูล</h4>
                <div slot="content" class="description">
                  <p>
                    089-1769303 <br />
                    Mon - Sun, 8:00-22:00
                  </p>
                </div>
              </info-areas>
              <info-areas
                class="pt-0"
                info-horizontal
                icon-color="primary"
                icon="business_center"
              >
                <h4 slot="title" class="info-title">ข้อมูลพื้นฐาน</h4>
                <div slot="content" class="description">
                  <p>Location: ห้วยขวาง กรุงเทพ ฯ</p>
                </div>
              </info-areas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";
import { InfoAreas } from "@/components";
export default {
  components: {
    InfoAreas,
  },
  bodyClass: "contact-us",
  mixins: [Mixins.InitMap],
  data() {
    return {
      name: null,
      email: null,
      phone: null,
      textarea: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.mt-3 {
  margin-top: 1.5rem;
}
</style>
