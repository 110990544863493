<template>
  <div class="wrapper">
    <!-- Features 1 -->
    <div
      class="section section-features-3"
      style="background-color: black; color: white"
    >
      <br />
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-50 md-small-size-100">
            <br /><br />
            <center>
              <img :src="require('@/assets/img/CBD-logo.png')" />

              <a style="color: white; font-size: 12px">
                0x70d15FBcaF738546569Be14d047803dB3D419205
              </a>
              <a
                href="#"
                @click="copyText('0x70d15FBcaF738546569Be14d047803dB3D419205')"
              >
                <i
                  class="fas fa-clone"
                  style="font-size: 16px; color: white"
                ></i>
              </a>
            </center>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
            <br />
            <br />
            <info-areas info-horizontal>
              <h2
                slot="title"
                class=""
                style="font-weight: 500; color: #3fd004"
              >
                CANNABIDIOL (CBD)
              </h2>
              <div slot="content" style="font-size: 18px; font-weight: 600">
                <ul>
                  <li>The name of the token: CANNABIDIOL TOKEN</li>
                  <li>Token's symbol: CBD</li>
                  <li>Type of Offer: Initial Exchange Offering</li>
                  <li>Standard: BEP20</li>
                  <li>Project Sector: Cannabidiol Research Fund</li>
                  <li>Token Issuance: 999,999,999,999 TOKENS</li>
                  <li>
                    Avlibale Token: 1,540,000,000 CBD (70% of total supply)
                  </li>
                </ul>
              </div>
            </info-areas>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <center>
          <a
            href="https://bscscan.com/token/0x70d15FBcaF738546569Be14d047803dB3D419205"
            target="_blink"
          >
            <img
              src="https://pbs.twimg.com/media/FG7XLAlUUAA-11O.png"
              style="
                width: 200px;
                margin: 10px;
                border-radius: 10px;
                border-color: #3fd004;
                border-style: solid;
                border-width: medium;
              "
            />
          </a>

          <a
            href="https://pancakeswap.finance/swap?outputCurrency=0x70d15FBcaF738546569Be14d047803dB3D419205"
            target="_blink"
          >
            <img
              src="https://www.siambitcoin.com/wp-content/uploads/2021/10/PancakeSwap.jpg"
              style="
                width: 200px;
                margin: 10px;
                border-radius: 10px;
                border-color: #3fd004;
                border-style: solid;
                border-width: medium;
              "
            />
          </a>
          <a href="https://www.365.stream/" target="_blink">
            <img
              src="https://www.365.stream/img/logo.64755377.svg"
              style="
                width: 200px;
                margin: 10px;
                border-radius: 10px;
                border-color: #3fd004;
                border-style: solid;
                border-width: medium;
              "
            />
          </a>
          <a href="https://github.com/365stream/Cannabidiol" target="_blink">
            <img
              src="https://p.kindpng.com/picc/s/128-1280187_github-logo-png-github-transparent-png.png"
              style="
                width: 200px;
                margin: 10px;
                border-radius: 10px;
                border-color: #3fd004;
                border-style: solid;
                border-width: medium;
              "
            />
          </a>
          <br />
          <br />
        </center>
      </div>
    </div>

    <div
      class="section section-team-4 section-image"
      :style="sectionTeam(team4)"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-80 md-small-size-100 mx-auto text-center"
          >
            <h3 class="title">CANNABIDIOL (CBD)</h3>
            <h5 style="font-weight: 500">
              Cannabidiol Token recognizes these problems. Therefore thought to
              raise funds to support the study and research of traditional
              medicines from marijuana. Every country in the world seriously
              wants to enable humanity to have access to medical treatment from
              marijuana It is truly a national drug, and sees the opportunity to
              change marijuana from drugs into medicines and may become drugs
              used to treat drug addicts. Addictive of all kinds throughout the
              world as well By starting to build a drug treatment center that
              The gold boundary between the countries of Thailand, Laos, Myanmar
              that has been dubbed by people from all over the world. That is
              the land of drugs to become the land of drug treatment in the
              world instead of the old saying.
            </h5>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item text-center">
            <h3 class="title">Road Map</h3>
          </div>

          <img :src="require('@/assets/img/roadmap-1.png')" />
        </div>
      </div>
    </div>

    <div class="section section-features-3" style="background-color: black">
      <br />
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item">
            <img
              src="https://www.infoquest.co.th/wp-content/uploads/2022/04/20220418_PRG_GUNKUL_%E0%B8%81%E0%B8%B1%E0%B8%8D%E0%B8%8A%E0%B8%87.jpg"
            />

            <center>
              <h3 slot="title" style="font-weight: 600; color: #3fd004">
                Conclusion
              </h3>
              <div slot="content" style="font-size: 18px; font-weight: 600">
                <h5 style="font-weight: 500">
                  Cannabidiol Coin raised funds for the construction. The
                  Cannabidiol Research Fund using the IEO method, when funded by
                  up to 20% of the total coins in the Cannabidiol Coin system,
                  will be implemented in accordance with the Road Map laid down
                  by Join in Cooperation with MoU with the public and private
                  sectors that share the same direction as us, pushing marijuana
                  to be a medicine. And accepted by people all over the world,
                  when coins the system was released for market participants to
                  hold the Cannabidiol Coin. Hopefully in the future it will be
                  the main currency for the purchase of medical marijuana
                  products in the world to facilitate general people. To access
                  medication from hemp extracts from various countries around
                  the world without any restrictions from trading in major
                  currencies of each country.
                </h5>
              </div>
            </center>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>

    <!-- Features 3 -->

    <!-- end Features 5 -->
  </div>
</template>

<script>
import { InfoAreas } from "@/components";
import { ProfileCard } from "@/components";

export default {
  name: "Features",
  components: {
    InfoAreas,
  },
  data() {
    return {
      iphone: require("@/assets/img/sections/iphone.jpg"),
      iphone2: require("@/assets/img/sections/iphone2.png"),
      image: require("@/assets/img/bg9.jpg"),
      cardProfile: {
        cardProfile1: require("@/assets/img/faces/marc.jpg"),
        cardProfile2: require("@/assets/img/faces/kendall.jpg"),
        cardProfile3: require("@/assets/img/faces/christian.jpg"),
        cardProfile4: require("@/assets/img/faces/avatar.jpg"),
      },
      cardProfile2: {
        cardProfile1: require("@/assets/img/examples/card-profile1.jpg"),
        cardProfile2: require("@/assets/img/examples/card-profile2.jpg"),
        cardProfile3: require("@/assets/img/examples/card-profile4.jpg"),
      },
      cardProfile3: {
        cardProfile1: require("@/assets/img/faces/card-profile1-square.jpg"),
        cardProfile2: require("@/assets/img/faces/card-profile6-square.jpg"),
        cardProfile3: require("@/assets/img/faces/card-profile4-square.jpg"),
        cardProfile4: require("@/assets/img/faces/card-profile2-square.jpg"),
      },
      team4:
        "https://assets.brandinside.asia/uploads/2018/06/shutterstock_734768755.jpg",
      team5: require("@/assets/img/examples/city.jpg"),
    };
  },
  computed: {
    features5() {
      return {
        backgroundImage: `url(${this.image})`,
      };
    },
  },
  methods: {
    sectionTeam(image) {
      return {
        backgroundImage: `url(${image})`,
      };
    },
    copyText(str) {
      const el = document.createElement("textarea");
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
  },
};
</script>

<style lang="scss"></style>
