<template>
  <div class="wrapper">
    <!-- Testimonials 1 -->
    <div
      class="section section-testimonials-1 section-image"
      :style="sectionTestimonials(testimonials1)"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-100 mx-auto text-center"
          >
            <h2 class="title">Review</h2>
            <h5 class="description">
              ความสำเร็จบางส่วนของเพื่อนๆผู้ใช้ Auto Rebalance
            </h5>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <testimonial-card>
              <md-icon slot="icon"> format_quote </md-icon>
              <template slot="cardContent">
                <iframe
                  width="950"
                  height="534"
                  src="https://www.youtube.com/embed/mzmI6QofeRE"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h5 class="card-description">
                  Bitดอย Chanel : EP3 ถอนกำไร 40,000 บาท มาซื้อกล้อง
                  "ยังเหลือกำไร 7xx,xxx บาท
                </h5>
              </template>
              <template slot="cardAction">
                <h6 class="card-category">@Bitดอย Chanel</h6>
                <div class="md-card-avatar">
                  <a href="javascript:void(0)">
                    <img
                      class="img"
                      src="https://yt3.ggpht.com/ytc/AKedOLRsxljjXmOuFhDkPdJjipyXjdis4-VqRmwn4SQW=s88-c-k-c0x00ffffff-no-rj"
                    />
                  </a>
                </div>
              </template>
            </testimonial-card>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <testimonial-card>
              <md-icon slot="icon"> format_quote </md-icon>
              <template slot="cardContent">
                <iframe
                  width="950"
                  height="534"
                  src="https://www.youtube.com/embed/nADdItG3ASU"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h5 class="card-description">
                  EP.2 Auto Rebalancing Robot on Binance กำไรวันเดียว 26,000
                  บาท(Clickbaitนะ)
                </h5>
              </template>
              <template slot="cardAction">
                <h6 class="card-category">@Game Academy</h6>
                <div class="md-card-avatar">
                  <a href="javascript:void(0)">
                    <img
                      class="img"
                      src="https://yt3.ggpht.com/ytc/AKedOLTA-YRww1sLQiAZXvqLVPI-awBctZCYCYZs4oVSwVo=s88-c-k-c0x00ffffff-no-rj"
                    />
                  </a>
                </div>
              </template>
            </testimonial-card>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <testimonial-card>
              <md-icon slot="icon"> format_quote </md-icon>
              <template slot="cardContent">
                <iframe
                  width="950"
                  height="534"
                  src="https://www.youtube.com/embed/c5p68IEvYB4"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h5 class="card-description">
                  วิธี Rebalance ในตลาด Crypto Currency #การลงทุนมีความเสี่ยง
                  ควรศึกษาข้อมูลก่อนตัดสินใจลงทุน
                </h5>
              </template>
              <template slot="cardAction">
                <h6 class="card-category">@เดียว Investor</h6>
                <div class="md-card-avatar">
                  <a href="javascript:void(0)">
                    <img
                      class="img"
                      src="https://yt3.ggpht.com/ytc/AKedOLTEthNruLbTuLZhBrzWX_eyPI5dBILv56xdMLX3=s88-c-k-c0x00ffffff-no-rj"
                    />
                  </a>
                </div>
              </template>
            </testimonial-card>
          </div>
        </div>
      </div>
    </div>
    <!-- end Testimonials 1 -->
    <div
      class="section section-testimonials-2"
      style="background-color: rgb(10, 10, 10)"
    >
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-50 mx-auto md-small-size-100">
            <md-card>
              <el-carousel trigger="click" :interval="2000">
                <el-carousel-item v-for="item in carousel" :key="item.image">
                  <h4 class="carousel-caption">
                    <md-icon>location_on</md-icon>
                    {{ item.title }}
                  </h4>
                  <img :src="item.image" alt="" />
                </el-carousel-item>
              </el-carousel>
            </md-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TestimonialCard } from "@/components";

export default {
  name: "Testimonials",
  components: {
    TestimonialCard,
  },
  data() {
    return {
      testimonials1: require("@/assets/img/dg2.jpg"),
      sectionTestimonials1: {
        testimonials1: require("@/assets/img/faces/card-profile1-square.jpg"),
        testimonials2: require("@/assets/img/faces/card-profile4-square.jpg"),
        testimonials3: require("@/assets/img/faces/card-profile2-square.jpg"),
      },
      sectionTestimonials2: {
        testimonials1: require("@/assets/img/faces/kendall.jpg"),
        testimonials2: require("@/assets/img/faces/christian.jpg"),
      },
      sectionTestimonials3: {
        testimonials1: require("@/assets/img/faces/card-profile6-square.jpg"),
      },
      carousel: [
        {
          image: require("@/assets/img/reviews/1.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/2.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/3.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/4.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/5.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/6.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/7.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/8.jpeg"),
        },
      ],
    };
  },
  methods: {
    sectionTestimonials(image) {
      return {
        backgroundImage: `url(${image})`,
      };
    },
  },
};
</script>

<style lang="scss">
.mt-0 {
  margin-top: 0 !important;
}
</style>
