<template>
  <div class="wrapper">
    <!-- Testimonials 1 -->
    <div class="section section-testimonials-1">
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-100 mx-auto text-center"
          >
            <h2 class="title">ทดลองดูการทำงานผ่าน Line</h2>
            <h5 class="description">เข้ากลุ่ม Line เพื่อดูการทำงานบอท</h5>
            <a href="http://line.me/ti/g/fqQ5X1GmYP" target="_blink">
              <img
                src="https://s.isanook.com/hi/0/rp/r/w850/ya0xa0m1w0/aHR0cHM6Ly9zLmlzYW5vb2suY29tL2hpLzAvdWQvMzEwLzE1NTA4NDEvdW50aXRsZWQtNC5qcGc=.jpg"
                style="width: 200px; border-width: medium; border-radius: 10px"
                alt=""
              />
              <br />
              <a href="http://line.me/ti/g/fqQ5X1GmYP" target="_blink"
                >http://line.me/ti/g/fqQ5X1GmYP</a
              >
            </a>
          </div>
        </div>
        <div>
          <h4>Social</h4>
          <div class="md-layout">
            <div v-for="color in colors" :key="color.name">
              <div id="social-buttons" class="social-buttons-demo">
                <a
                  v-if="color.name == 'facebook'"
                  :href="color.link"
                  target="_blink"
                >
                  <md-button :class="'md-' + color.name">
                    <i :class="'fab fa-' + color.icon" />
                    {{ color.btnText }}
                  </md-button>
                </a>
                <a v-else>
                  <md-button :class="'md-' + color.name">
                    <i :class="'fab fa-' + color.icon" />
                    {{ color.btnText }}
                  </md-button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Social",
  components: {},
  data() {
    return {
      testimonials1: require("@/assets/img/dg2.jpg"),
      sectionTestimonials1: {
        testimonials1: require("@/assets/img/faces/card-profile1-square.jpg"),
        testimonials2: require("@/assets/img/faces/card-profile4-square.jpg"),
        testimonials3: require("@/assets/img/faces/card-profile2-square.jpg"),
      },
      sectionTestimonials2: {
        testimonials1: require("@/assets/img/faces/kendall.jpg"),
        testimonials2: require("@/assets/img/faces/christian.jpg"),
      },
      sectionTestimonials3: {
        testimonials1: require("@/assets/img/faces/card-profile6-square.jpg"),
      },
      carousel: [
        {
          image:
            "https://scontent.fbkk2-8.fna.fbcdn.net/v/t1.6435-9/134448266_231435325177810_1823284449683199568_n.jpg?_nc_cat=100&ccb=1-5&_nc_sid=8bfeb9&_nc_eui2=AeFot8OxNAF3PtM9Y-vHfFQx6sfk6xXDe7bqx-TrFcN7trPP8TpCrktb4t9R4F0G-LZ9HAvPHnYXXlrBz3c3vGIs&_nc_ohc=WEAYRmVnB6cAX_CtJq4&_nc_ht=scontent.fbkk2-8.fna&oh=00_AT__ui0R5T7D2mb6xynUjtQka-rUwvAdHPK-ttHDqBVvuw&oe=6249879A",
          title:
            "สมาชิกใหม่..ที่เปิดโอกาสให้ทาง AutoRebalance แนะนำเทคนิคกลยุทธ์บริหารความเสี่ยง ในตลาดคริปโตเคอเรนซี่",
        },
        {
          image:
            "https://scontent.fbkk2-6.fna.fbcdn.net/v/t1.6435-9/138954421_235053861482623_7547359937046431328_n.jpg?_nc_cat=107&ccb=1-5&_nc_sid=8bfeb9&_nc_eui2=AeGzcfo3XeDhxvYMWttlG_VEongKHGQFOZ6ieAocZAU5nt6zJMuh1BL3Ftcd4Szy2ZeohCiEh2xcP5LIe9VsZo1N&_nc_ohc=eVVOkV622DUAX_3RPl1&_nc_ht=scontent.fbkk2-6.fna&oh=00_AT94c72OQff59Ob6UBys5FdeVjGE_K4BcMqdJg4_eWhwhw&oe=624D2B67",
          title:
            "สมาชิกใหม่..ที่เปิดโอกาสให้ทาง AutoRebalance แนะนำเทคนิคกลยุทธ์บริหารความเสี่ยง ในตลาดคริปโตเคอเรนซี่",
        },
      ],
      colors: [
        {
          name: "facebook",
          icon: "facebook-f",
          iconSquare: "facebook-square",
          btnText: "facebook",
          link: "https://www.facebook.com/autorebalance",
        },
        {
          name: "twitter",
          icon: "twitter",
          iconSquare: "twitter",
          btnText: "twitter",
          link: "/",
        },
      ],
    };
  },
  methods: {
    sectionTestimonials(image) {
      return {
        backgroundImage: `url(${image})`,
      };
    },
  },
};
</script>

<style lang="scss">
.mt-0 {
  margin-top: 0 !important;
}
</style>
