<template>
  <div class="wrapper">
    <div id="main-panel" class="main">
      <div id="features" class="cd-section">
        <features />
      </div>
    </div>
  </div>
</template>

<script>
import Headers from "./sections/Headers";
import Features from "./sections/Features";
import Blogs from "./sections/Blogs";
import Teams from "./sections/Teams";
import Projects from "./sections/Projects";
import Pricing from "./sections/Pricing";
import Testimonials from "./sections/Testimonials";
import ContactUs from "./sections/ContactUs";
import Supports from "./sections/Supports";
import Social from "./sections/Social";

import Mixins from "@/plugins/basicMixins";
import Swal from "sweetalert2";

export default {
  components: {
    Features,
  },
  mixins: [Mixins.VerticalNav],
  methods: {},
  created() {},
};
</script>

<style lang="scss">
.vertical-nav-active {
  display: block;
}

@media all and (max-width: 768px) {
  .vertical-nav-active {
    display: none;
  }
}
</style>
