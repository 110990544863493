<template>
  <footer
    class="footer"
    :class="{
      [`footer-${type}`]: type,
      [`footer-${size}`]: size,
    }"
    :data-background-color="backgroundColor"
  >
    <!-- <template>
      <div class="container">
        <nav>
          <ul>
            <li>
              <a href="https://www.creative-tim.com"> Creative Tim </a>
            </li>
            <li>
              <a href="https://www.creative-tim.com/presentation"> About Us </a>
            </li>
            <li>
              <a href="http://blog.creative-tim.com"> Blog </a>
            </li>
            <li>
              <a href="https://www.creative-tim.com/license"> Licenses </a>
            </li>
          </ul>
        </nav>
        <div class="copyright">
          &copy; {{ year }}, made with <md-icon>favorite</md-icon> by
          <a href="https://www.creative-tim.com/" target="_blank"
            >Creative Tim</a
          >
          for a better web.
        </div>
      </div>
    </template> -->

    <template>
      <div class="container flex-direction-row">
        <a style="font-size: 14px">
          <div class="copyright">&copy; {{ year }}, 2022 CBD</div>
        </a>
        <ul>
          <li>
            <a target="_blink" href="static/whitepaper.pdf"> WHITE PAPER </a>
          </li>

          <li>
            <a
              target="_blink"
              href="https://365-stream-admin.gitbook.io/cannabidiol-cbd-token/?fbclid=IwAR0c6HgzuF0QW_55Xsr5rKIK966JesyQ0du4LkkbaX1t1SMnO9W3PJnHDvs"
            >
              WHITE PAPER
            </a>
          </li>
        </ul>

        <ul class="social-buttons">
          <li>
            <md-button
              href="https://www.facebook.com/365StreamBritain"
              target="_blank"
              class="md-twitter md-just-icon md-simple"
            >
              <i class="fab fa-facebook" />
            </md-button>
          </li>
          <li>
            <md-button
              href="https://twitter.com/365streamUK"
              target="_blank"
              class="md-twitter md-just-icon md-simple"
            >
              <i class="fab fa-twitter" />
            </md-button>
          </li>
          <li>
            <md-button
              href="https://medium.com/@365.stream"
              target="_blank"
              class="md-just-icon md-simple"
            >
              <i class="fab fa-medium" />
            </md-button>
          </li>
          <li>
            <md-button
              href="https://www.reddit.com/search/?q=365.stream"
              target="_blank"
              class="md-reddit md-just-icon md-simple"
            >
              <i class="fab fa-reddit" />
            </md-button>
          </li>

          <li>
            <md-button
              href="https://www.instagram.com/365.streamuk/"
              target="_blank"
              class="md-instagram md-just-icon md-simple"
            >
              <i class="fab fa-instagram" />
            </md-button>
          </li>
          <li>
            <md-button
              href="https://discord.gg/PZEBrMKu"
              target="_blank"
              class="md-discord md-just-icon md-simple"
            >
              <i class="fab fa-discord" />
            </md-button>
          </li>
          <li>
            <md-button
              href=""
              target="_blank"
              class="md-twitter md-just-icon md-simple"
            >
              <i class="fab fa-telegram" />
            </md-button>
          </li>
        </ul>
      </div>
    </template>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
    size: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      img1: require("@/assets/img/faces/card-profile6-square.jpg"),
      img2: require("@/assets/img/faces/christian.jpg"),
      img3: require("@/assets/img/faces/card-profile4-square.jpg"),
      img4: require("@/assets/img/faces/card-profile1-square.jpg"),
      img5: require("@/assets/img/faces/marc.jpg"),
      img6: require("@/assets/img/faces/kendall.jpg"),
      img7: require("@/assets/img/faces/card-profile5-square.jpg"),
      img8: require("@/assets/img/faces/card-profile2-square.jpg"),
    };
  },
};
</script>
<style></style>
