<template>
  <div class="wrapper">
    <div style="width: 150px; height: 200px"></div>
    <div class="main main-raised">
      <div class="section section-contact">
        <div class="container">
          <div class="md-layout" id="headers">
            <div class="md-layout-item md-size-90 md-small-size-100 mx-auto">
              <h4 class="title">SET REBALANCE</h4>
              <div class="container">
                <div class="md-layout">
                  <md-button
                    :class="rebalanceSet_1_5_years"
                    @click="setRebalance('1.5years')"
                  >
                    1 ปี 5 เดือน
                  </md-button>
                  <md-button
                    :class="rebalanceSet_1_years"
                    @click="setRebalance('1year')"
                  >
                    1 ปี
                  </md-button>
                  <md-button
                    :class="rebalanceSet_6_month"
                    @click="setRebalance('6month')"
                  >
                    6 เดือน
                  </md-button>
                  <md-button
                    :class="rebalanceSet_3_month"
                    @click="setRebalance('3month')"
                  >
                    3 เดือน
                  </md-button>
                  <md-button
                    :class="rebalanceSet_1_month"
                    @click="setRebalance('1month')"
                  >
                    1 เดือน
                  </md-button>
                </div>
              </div>
              <h4 class="title">SET STRATING AMOUNT REBALANCE</h4>
              <div class="container">
                <div class="md-layout">
                  <md-button
                    :class="rebalanceSet_amount_30000"
                    @click="setAmountRebalance('30000')"
                  >
                    30,000 USDT
                  </md-button>

                  <md-button
                    :class="rebalanceSet_amount_10000"
                    @click="setAmountRebalance('10000')"
                  >
                    10,000 USDT
                  </md-button>
                  <md-button
                    :class="rebalanceSet_amount_5000"
                    @click="setAmountRebalance('5000')"
                  >
                    5,000 USDT
                  </md-button>
                  <md-button
                    :class="rebalanceSet_amount_1000"
                    @click="setAmountRebalance('1000')"
                  >
                    1,000 USDT
                  </md-button>
                  <md-button
                    :class="rebalanceSet_amount_specify"
                    @click="setAmountRebalance('')"
                    >ระบุจำนวน
                    <md-input v-model="amountRebalance" type="text" /> USDT
                  </md-button>
                </div>
              </div>
              <div class="container">
                <h4 class="title">SELECT ASSETS</h4>
                <div class="md-layout">
                  <div
                    v-for="index in coinList"
                    :key="index"
                    class="md-layout-item md-size-15 md-small-size-33"
                  >
                    <md-button
                      v-if="coins.includes(index)"
                      class="md-success"
                      @click="selectCoins(index)"
                    >
                      <center>
                        <img
                          :src="require(`@/assets/tokens/${index}.png`)"
                          style="width: 80%"
                          alt=""
                        />
                        <br />
                        <a
                          style="
                            font-size: 12px;
                            font-weight: 500;
                            color: white;
                          "
                        >
                          {{ index }}
                        </a>
                      </center>
                    </md-button>
                    <md-button v-else class="" @click="selectCoins(index)">
                      <center>
                        <img
                          :src="require(`@/assets/tokens/${index}.png`)"
                          style="width: 80%"
                          alt=""
                        />
                        <br />
                        <a
                          style="
                            font-size: 12px;
                            font-weight: 500;
                            color: white;
                          "
                        >
                          {{ index }}
                        </a>
                      </center>
                    </md-button>
                  </div>
                </div>
                <br />

                <md-button class="md-success md-lg" @click="calculators()">
                  CALCULATOR
                </md-button>
                <a style="font-size: 14px; color: black"
                  >( หลังจาก <b>Reset</b> ข้อมูล กด <b>CALCULATOR</b> เพื่อคำนวน
                  <b>Backtest</b> )
                </a>
              </div>
              <div style="display: flex; justify-content: flex-end">
                <a href="javascript:void(0)" @click="showTransaction()"
                  ><b>Show Transaction</b></a
                >
              </div>

              <!-- <h4 class="title">Summary</h4>
              <div class="container">
                <div class="md-layout">
                  <div
                    v-for="index in coins"
                    :key="index"
                    class=""
                    style="margin: 3px"
                  >
                    <img
                      :src="require(`@/assets/tokens/${index}.png`)"
                      style="width: 50%"
                    />
                    <p style="font-size: 14px; font-weight: 500; color: black">
                      {{ index }}
                      <br />
                      <a style="font-size: 12px; color: black"
                        >({{ ratio }}%)</a
                      >
                    </p>
                  </div>
                </div>
                <br />
              </div> -->

              <br />
              <br />

              <canvas id="planet-chart"></canvas>
              <br />

              <p style="font-size: 14px; font-weight: 500">
                ทั้งนี้การใช้งาน AutoRebalance , Backtest และ Forwardtest
                ไม่สามารถการันตีผลลัพธ์หรือผลกำไรในอนาคตได้
                การใช้งานดังกล่าวมีความเสี่ยง
                ไม่แนะนำให้ใช้งานหากไม่ทำการศึกษาข้อมูล
                ข้อจำกัดและยอมรับเงื่อนไขการใช้งานทั้งปัจจุบันและในอนาคต
                ผู้ใช้งานควรยอมรับความเสี่ยงก่อนติดสินใจใช้งาน.
              </p>
              <br />
              <br />
            </div>
          </div>

          <div v-if="coins_list.length > 0">
            <div class="container">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-30 md-small-size-100"
                  style="
                    border-style: solid;
                    margin: 5px;
                    background-color: #c8fe7e;
                    border-radius: 15px;
                  "
                >
                  <h4 class="title">AutoRebalance Backtest :</h4>
                  <p v-for="coo in coins_list" :key="coo">
                    Start <b>{{ coo }}</b
                    >:
                    <a style="font-weight: 600">{{
                      summary.rebalance.start[`${coo}`].amount_coin
                    }}</a>
                    <b> {{ coo }}</b>
                  </p>

                  <p>
                    Start <b>Summary</b>:
                    <a style="font-weight: 600">{{ startSummary }}</a>
                    <b> USDT</b>
                  </p>
                  <br /><br />

                  <p v-for="c in coins_list" :key="c">
                    Last <b>{{ c }}</b> :
                    <a style="font-weight: 600">
                      {{ summary.rebalance.last[`${c}`].amount_coin }}</a
                    >
                    <b> {{ c }}</b>
                  </p>
                  <p>
                    Last <b>Summary</b>:
                    <a style="font-weight: 600">{{
                      summary.rebalance.summary
                    }}</a>
                    <b> USDT</b>
                  </p>
                </div>
                <div
                  class="md-layout-item md-size-30 md-small-size-100"
                  style="
                    border-style: solid;
                    margin: 5px;
                    background-color: #fec27e;
                    border-radius: 15px;
                  "
                >
                  <h4 class="title">Hold Backtest :</h4>
                  <p v-for="c in coins_list" :key="c">
                    Start <b>{{ c }}</b
                    >:
                    <a style="font-weight: 600">{{
                      summary.hold.start[c].amount_coin
                    }}</a>
                    <b> {{ c }}</b>
                  </p>

                  <p>
                    Start <b>Summary</b>:
                    <a style="font-weight: 600">{{ startSummary }}</a>
                    <b> USDT</b>
                  </p>
                  <br />
                  <br />
                  <p v-for="c in coins_list" :key="c">
                    Last <b>{{ c }}</b
                    >:
                    <a style="font-weight: 600">{{
                      summary.hold.last[c].amount_coin
                    }}</a>
                    <b> {{ c }}</b>
                  </p>
                  <p>
                    Last<b> Summary</b>:
                    <a style="font-weight: 600">{{ summary.hold.summary }}</a>
                    <b> USDT</b>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <md-table
            v-if="showTran"
            v-model="tableDataStriped"
            class="table-striped"
          >
            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              md-auto-select
            >
              <md-table-cell md-label="summaryRebalance">
                {{ item.summaryRebalance }}
              </md-table-cell>
              <md-table-cell md-label="summaryHold">
                {{ item.summaryHold }}
              </md-table-cell>

              <md-table-cell md-label="Price">
                {{ item.price }}
              </md-table-cell>
              <md-table-cell md-label="Amount">
                {{ item.coin }}
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.7.1/Chart.min.js"></script>
<script src="https://unpkg.com/vue-chartjs/dist/vue-chartjs.min.js"></script>

<script>
import Chart from "chart.js";
import axios from "axios";
import Swal from "sweetalert2";

import Mixins from "@/plugins/basicMixins";
import { API_URL } from "../../config";

export default {
  name: "PlanetChart",
  mixins: [Mixins.ShoppingCart],

  data() {
    return {
      rebalanceSet_1_5_years: "",
      rebalanceSet_1_years: "md-success",
      rebalanceSet_6_month: "",
      rebalanceSet_3_month: "",
      rebalanceSet_1_month: "",
      rebalanceSet_amount_30000: "",
      rebalanceSet_amount_10000: "md-success",
      rebalanceSet_amount_5000: "",
      rebalanceSet_amount_1000: "",
      rebalanceSet_amount_specify: "",
      amountRebalance: 0,
      startSummary: 0,
      ratio: "50",
      showTran: false,
      coinList: [
        "BTC",
        "ETH",
        "BNB",
        "ADA",
        "LUNA",
        "XRP",
        "SOL",
        "AVAX",
        "DOT",
        "DOGE",
        "USDT",
      ],
      coins: ["BTC", "USDT"],
      coins_list: [],
      planetChartData: {
        type: "line",
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  padding: 25,
                },
              },
            ],
          },
        },
      },
      summary: {
        hold: {
          summary: "12381.67",
          last: {
            USDT: { amount_coin: 0, raio: 0, price: 0 },
            BTC: {
              amount_coin: "0.210858",
              raio: "5000",
              price: "47434.80000000",
            },
          },
          start: {
            USDT: { amount_coin: "5000", raio: "5000", price: 1 },
            BTC: {
              amount_coin: "0.105429",
              raio: "5000",
              price: "47425.20000000",
            },
          },
        },
        rebalance: {
          summary: "11928.73",
          last: {
            USDT: { amount_coin: "5964", raio: "5964", price: 1 },
            BTC: {
              amount_coin: "0.101566",
              raio: "5964",
              price: "58740.55000000",
            },
          },
          start: {
            USDT: { amount_coin: "5000", raio: "5000", price: 1 },
            BTC: {
              amount_coin: "0.105429",
              raio: "5000",
              price: "47425.20000000",
            },
          },
        },
      },
      tableDataStriped: [
        {
          id: 1,
          name: "Moleskine Agenda",
          type: "Office",
          qty: "25",
          price: "€ 49",
          amount: "€ 1,225",
        },
      ],
    };
  },
  methods: {
    setAmountRebalance(text) {
      if (text == "30000") {
        this.rebalanceSet_amount_30000 = "md-success";
        this.rebalanceSet_amount_10000 = "";
        this.rebalanceSet_amount_5000 = "";
        this.rebalanceSet_amount_1000 = "";
        this.rebalanceSet_amount_specify = "";
      } else if (text == "10000") {
        this.rebalanceSet_amount_30000 = "";
        this.rebalanceSet_amount_10000 = "md-success";
        this.rebalanceSet_amount_5000 = "";
        this.rebalanceSet_amount_1000 = "";
        this.rebalanceSet_amount_specify = "";
      } else if (text == "5000") {
        this.rebalanceSet_amount_30000 = "";
        this.rebalanceSet_amount_10000 = "";
        this.rebalanceSet_amount_5000 = "md-success";
        this.rebalanceSet_amount_1000 = "";
        this.rebalanceSet_amount_specify = "";
      } else if (text == "1000") {
        this.rebalanceSet_amount_30000 = "";
        this.rebalanceSet_amount_10000 = "";
        this.rebalanceSet_amount_5000 = "";
        this.rebalanceSet_amount_1000 = "md-success";
        this.rebalanceSet_amount_specify = "";
      } else {
        this.rebalanceSet_amount_30000 = "";
        this.rebalanceSet_amount_10000 = "";
        this.rebalanceSet_amount_5000 = "";
        this.rebalanceSet_amount_1000 = "";
        this.rebalanceSet_amount_specify = "md-success";
      }
    },
    setRebalance(text) {
      if (text == "1.5years") {
        this.rebalanceSet_1_5_years = "md-success";
        this.rebalanceSet_1_years = "";
        this.rebalanceSet_6_month = "";
        this.rebalanceSet_3_month = "";
        this.rebalanceSet_1_month = "";
      } else if (text == "1year") {
        this.rebalanceSet_1_5_years = "";
        this.rebalanceSet_1_years = "md-success";
        this.rebalanceSet_6_month = "";
        this.rebalanceSet_3_month = "";
        this.rebalanceSet_1_month = "";
      } else if (text == "6month") {
        this.rebalanceSet_1_5_years = "";
        this.rebalanceSet_1_years = "";
        this.rebalanceSet_6_month = "md-success";
        this.rebalanceSet_3_month = "";
        this.rebalanceSet_1_month = "";
      } else if (text == "3month") {
        this.rebalanceSet_1_5_years = "";
        this.rebalanceSet_1_years = "";
        this.rebalanceSet_6_month = "";
        this.rebalanceSet_3_month = "md-success";
        this.rebalanceSet_1_month = "";
      } else if (text == "1month") {
        this.rebalanceSet_1_5_years = "";
        this.rebalanceSet_1_years = "";
        this.rebalanceSet_6_month = "";
        this.rebalanceSet_3_month = "";
        this.rebalanceSet_1_month = "md-success";
      }
    },
    selectCoins(coin) {
      if (!this.coins.includes(coin)) {
        this.coins.push(coin);
        this.ratio = (100 / this.coins.length).toFixed(0);
      } else {
        this.removeA(this.coins, coin);
        this.ratio = (100 / this.coins.length).toFixed(0);
      }
      // setTimeout(() => {
      //   this.planetChartData.data.labels = [];
      //   this.planetChartData.data.datasets = [];

      //   this.backTest();
      // }, 2500);
    },
    removeA(arr) {
      var what,
        a = arguments,
        L = a.length,
        ax;
      while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1);
        }
      }
      this.coins = arr;
    },
    calculators() {
      Swal.fire({
        title: `คำเตือน`,
        text: `ทั้งนี้การใช้งาน AutoRebalance , Backtest และ Forwardtest ไม่สามารถการันตีผลลัพธ์หรือผลกำไรในอนาคตได้
                การใช้งานดังกล่าวมีความเสี่ยง ไม่แนะนำให้ใช้งานหากไม่ทำการศึกษาข้อมูล ข้อจำกัดและยอมรับเงื่อนไขการใช้งานทั้งปัจจุบันและในอนาคต
                ผู้ใช้งานควรยอมรับความเสี่ยงก่อนติดสินใจใช้งาน`,
        type: "success",
        confirmButtonClass: "btn btn-success",
        confirmButtonText: "ฉันเข้าใจและยอมรับ",
        buttonsStyling: false,
      });

      var timing = 0;

      if (this.rebalanceSet_1_5_years == "md-success") {
        timing = 540;
      } else if (this.rebalanceSet_1_years == "md-success") {
        timing = 365;
      } else if (this.rebalanceSet_6_month == "md-success") {
        timing = 183;
      } else if (this.rebalanceSet_3_month == "md-success") {
        timing = 91;
      } else if (this.rebalanceSet_1_month == "md-success") {
        timing = 31;
      }

      var amount = 0;

      if (this.rebalanceSet_amount_30000 == "md-success") {
        amount = 30000;
      } else if (this.rebalanceSet_amount_10000 == "md-success") {
        amount = 10000;
      } else if (this.rebalanceSet_amount_5000 == "md-success") {
        amount = 5000;
      } else if (this.rebalanceSet_amount_1000 == "md-success") {
        amount = 1000;
      } else if (this.rebalanceSet_amount_specify == "md-success") {
        amount = this.amountRebalance;
      }

      setTimeout(() => {
        this.backTest(timing, amount);
      }, 1000);
    },
    backTest(timing = 365, amount = 10000) {
      this.showTran = false;
      this.planetChartData.data.labels = [];
      this.planetChartData.data.datasets = [];
      this.tableDataStriped = [];

      // .post(`http://localhost:5000/backtest`, { coins: this.coins, timing })

      // .post(`https://api.mineco.in/backtest`, { coins: this.coins, timing })

      axios
        .post(`${API_URL}/backtest`, {
          coins: this.coins,
          timing,
          amount,
        })

        .then((response) => {
          this.planetChartData.data.datasets.push({
            label: "Rebalance",
            data: response.data.summaryAll,
            backgroundColor: "rgba(23,242,93,.6)",
            borderColor: "#36495d",
            borderWidth: 1,
          });

          this.planetChartData.data.datasets.push({
            label: "Hold",
            data: response.data.summaryHold,
            backgroundColor: "rgba(242,144,4,.7)",
            borderColor: "#6966",
            borderWidth: 1,
          });

          this.startSummary = response.data.startSummary;

          this.planetChartData.data.labels = response.data.priceCoin;
          this.summary = response.data.summary;

          this.coins_list = [];

          var txt = ``;
          // response.data.coinList.map((b) => {
          //   txt =
          //     txt + `${b}: ${response.data.amount_ALL[1].q[b].amount_coin} | `;
          // });

          this.coins.map((c) => {
            this.coins_list.push(c);
          });

          const ctx = document.getElementById("planet-chart");
          new Chart(ctx, this.planetChartData);

          for (var i = 0; i < response.data.summaryAll.length; i++) {
            var txt = ``;
            response.data.coinList.map((b) => {
              response.data.amount_ALL[i].map((cc) => {
                if (cc.name == b) {
                  txt = txt + `${cc.name}: ${cc.amount_coin} | `;
                }
              });
            });

            this.tableDataStriped.push({
              summaryRebalance: response.data.summaryAll[i],
              summaryHold: response.data.summaryHold[i],
              price: response.data.priceCoin[i],
              coin: txt,
            });
          }
        })
        .catch((error) => {
          console.log("error00001", error);
        });
    },
    showTransaction() {
      if (this.showTran) {
        this.showTran = false;
      } else {
        this.showTran = true;
      }
    },
  },
  created() {
    this.backTest();
  },
  mounted() {
    // const ctx = document.getElementById("planet-chart");
    // new Chart(ctx, this.planetChartData);
  },
};
</script>

<style lang="scss">
.table-stats {
  display: flex;
  align-items: center;
  text-align: right;
  flex-flow: row wrap;

  .td-price .td-total {
    display: inline-flex;
    font-weight: 500;
    font-size: 1.0625rem;
    margin-right: 50px;
  }

  .td-price {
    font-size: 26px;
    border-top: 1px solid #ddd;
  }

  .td-price,
  > div {
    flex: 0 0 100%;
    padding: 12px 8px;
  }
}
</style>
