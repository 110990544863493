<template>
  <div class="wrapper">
    <div class="section section-blogs-3">
      <!-- Blogs 3 -->
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-80 md-small-size-100 mx-auto">
            <h3 class="title">Auto Rebalance Blog</h3>
            <div v-if="items.length > 0">
              <div v-for="item in items" :key="item.title">
                <blog-card
                  card-plain
                  type="horizontal"
                  :shadow-normal="false"
                  :no-colored-shadow="false"
                  :card-image="img[item.created]"
                >
                  <template slot="cardContent">
                    <h6 class="card-category text-success">Medium</h6>
                    <h4 class="card-title">
                      <a :href="item.link" target="_blink">{{ item.title }} </a>
                    </h4>
                    <p class="card-description">
                      {{ content_encoded[item.created] }}
                      <!-- {{ item.content_encoded.slice(3, 300) }}... -->
                      <a :href="item.link" target="_blink"> Read More </a>
                    </p>
                    <p class="author">
                      by
                      <a
                        href="https://medium.com/@aiautorebalance"
                        target="_blink"
                      >
                        <b>Autorebalance</b> </a
                      >, 2 days ago
                    </p>

                    <!-- {{ item.content_encoded }} -->
                  </template>
                </blog-card>
              </div>
            </div>
            <blog-card
              card-plain
              type="horizontal"
              :shadow-normal="false"
              :no-colored-shadow="false"
              :card-image="cardBlogPost.cardBlogPost1"
            >
              <template slot="cardContent">
                <h6 class="card-category text-info">Enterprise</h6>
                <h4 class="card-title">
                  <a
                    href="https://cryptonews.in.th/auto-reblance-bot-trade-crypto-valentine-promotion-buy-1-get-1-free/"
                    target="_blink"
                    >Auto Rebalance มอบโปรโมชั่นสุดพิเศษ เป็น “โปรสุดเลิฟ Buy 1
                    Get 1 Free”
                  </a>
                </h4>
                <p class="card-description">
                  AUTOREBALANCE ได้พัฒนาขึ้นมาจากทีมพัฒนาที่มีประสบการณ์กว่า 5
                  ปี ในตลาดคริปโตฯ โดยมีกลยุทธ์หลักคือ ปรับพอท Ratio หรือ Amount
                  Summary
                  หากใครที่สนใจแนะนำให้ทดลองศึกษาการทำงานและเงื่อนไขการใช้บริการก่อน
                  การใช้งาน Bot
                  มีค่าใช้จ่ายเป็นรายปีโดยมีหลายแพ็กเก็จให้เลือกตามความต้องการเทรดของผู้ใช้งาน
                  ...
                  <a
                    href="https://cryptonews.in.th/auto-reblance-bot-trade-crypto-valentine-promotion-buy-1-get-1-free/"
                    target="_blink"
                  >
                    Read More
                  </a>
                </p>
                <p class="author">
                  by
                  <a
                    href="https://cryptonews.in.th/auto-reblance-bot-trade-crypto-valentine-promotion-buy-1-get-1-free/"
                    target="_blink"
                  >
                    <b>cryptonews.in.th</b> </a
                  >, 2 days ago
                </p>
              </template>
            </blog-card>
            <blog-card
              card-plain
              type="horizontal"
              :shadow-normal="false"
              :no-colored-shadow="false"
              :card-image="cardBlogPost2.cardBlogPost1"
            >
              <template slot="cardContent">
                <h6 class="card-category text-success">
                  <md-icon class="text-success"> trending_up </md-icon> Review
                </h6>
                <h4 class="card-title">
                  <a
                    href="https://cryptonews.in.th/review-bot-trade-autorebalance/"
                    target="_blink"
                    >[รีวิว] บอทเทรดคริปโต Auto Rebalance
                    บอทที่ช่วยจะให้คุณทำกำไรได้ตลอดเวลาแม้นอนหลับ</a
                  >
                </h4>
                <p class="card-description">
                  แม้ว่าตลาดคริปโตจะมีการปรับฐานราคาลดลงหลายครั้งในช่วง 10
                  ปีที่ผ่านมา แต่ก็เป็นที่ทราบกันดีว่า Bitcoin
                  มักจะมีการฟื้นตัวกลับมาได้อยู่เสมอ ตัวอย่างเช่น ในปี 2018
                  หลังจากราคาร่วงลดลงแตะ 3,300 ดอลลาร์
                  ราคาก็สามารถดีดกลับขึ้นมาทดสอบ 13,000 ดอลลาร์ได้ในปี 2019
                  หรือในปี 2020 ...
                  <a
                    href="https://cryptonews.in.th/review-bot-trade-autorebalance/"
                    target="_blink"
                  >
                    Read More
                  </a>
                </p>
                <p class="author">
                  by
                  <a
                    href="https://cryptonews.in.th/review-bot-trade-autorebalance/"
                    target="_blink"
                  >
                    <b>cryptonews.in.th</b> </a
                  >, 20 days ago
                </p>
              </template>
            </blog-card>

            <blog-card
              card-plain
              type="horizontal"
              :shadow-normal="false"
              :no-colored-shadow="false"
              :card-image="cardBlogPost3.cardBlogPost1"
            >
              <template slot="cardContent">
                <h6 class="card-category text-success">Startups</h6>
                <h4 class="card-title">
                  <a
                    href="https://www.facebook.com/autorebalance/photos/a.129502252037785/493958238925516/"
                    target="_blink"
                    >6 อันดับ Fan Tokens ที่มี MarketCap สูงที่สุด
                  </a>
                </h4>
                <p class="card-description">
                  Autorebalanceไม่ใช่การชักชวนลงทุน
                  หรือผู้ให้คำแนะนำการลงทุนและการใช้งานดังกล่าวมีความเสี่ยง
                  เราไม่สามารถการันตีผลลัพธ์ได้ว่ากำไรหรือขาดทุนเหมือนตัวอย่างที่รีวิว
                  “Autorebalance เป็นเพียงผู้ให้บริการ บอทเทรดเท่านั้น”...
                  <a
                    href="https://www.facebook.com/autorebalance/photos/a.129502252037785/493958238925516/"
                    target="_blink"
                  >
                    Read More
                  </a>
                </p>
                <p class="author">
                  by
                  <a
                    href="https://www.facebook.com/autorebalance/photos/a.129502252037785/493958238925516/"
                    target="_blink"
                  >
                    <b>Megan Rose</b> </a
                  >, 2 days ago
                </p>
              </template>
            </blog-card>
          </div>
        </div>
      </div>
      <!-- End Blogs 3 -->
    </div>
  </div>
</template>

<script>
import { BlogCard } from "@/components";
import axios from "axios";
import { API_URL } from "../../../config";

export default {
  name: "Blogs",
  components: {
    BlogCard,
  },
  data() {
    return {
      cardBlogPost: {
        cardBlogPost1:
          "https://cryptonews.in.th/wp-content/uploads/2022/02/S__5226628.jpg",
      },
      cardBlogPost2: {
        cardBlogPost1:
          "https://cryptonews.in.th/wp-content/uploads/2021/03/1_A1x1XR7PJEhZiSqSNWxvvw.png",
      },
      cardBlogPost3: {
        cardBlogPost1: require("@/assets/img/1.jpeg"),
      },
      items: [],
      img: {},
      content_encoded: {},
    };
  },
  methods: {},
  created() {
    var b = {};
    var content_encoded = {};
    axios.get(`${API_URL}/mediumFeed`).then((response) => {
      this.items = response.data.rss.items;

      response.data.rss.items.map((i) => {
        var txt = i.content_encoded.substring(
          i.content_encoded.lastIndexOf(`<img alt="" src="`)
        );

        b[i.created] = txt.substring(17, txt.lastIndexOf(" />") - 1);

        var c = i.content_encoded.replace("</p><p>", " ").slice(0, 300);
        c = c.replace("</p>", " ");
        c = c.replace("<p>", " ");
        c = c.replace("<br>", " ");
        c = c.replace("</h3>", " ");
        c = c.replace("<h3>", "");
        c = c.replace("<br>", " ");
        content_encoded[i.created] = c;
      });

      this.img = b;
      this.content_encoded = content_encoded;
    });
  },
};
</script>

<style lang="scss"></style>
