<template>
  <div class="wrapper">
    <div class="section section-header-2 pt-0 pb-0">
      <div class="page-header header-filter">
        <div class="container">
          <div class="md-layout">
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <link
              rel="stylesheet"
              href="https://www.w3schools.com/w3css/4/w3.css"
            />

            <div
              class="md-layout-item md-size-66 md-small-size-100 md-xlarge-size-80 mx-auto text-center"
            >
              <div class="cssanimation sequence fadeInBottom">
                <h2 class="title">
                  Auto Rebalance ผู้ให้บริการ เครื่องมือช่วยเทรด
                </h2>
                <h3>
                  <span style="font-weight: 600">Bot</span> เทรด
                  <span style="font-weight: 600">Cryptocurrency</span>
                  สกุลเงินดิจิตอล บน
                  <span style="font-weight: 600">Binance</span> ความเสี่ยงต่ำ
                  กำไรสูง พร้อมทำกำไรตั้งแต่วันแรกที่เริ่มใช้งาน
                  ด้วยสูตรการคำนวณทางคณิตศาตร์ที่ผ่านการวิเคราะห์ด้วย
                  <span style="font-weight: 600">AI</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end Headers 2 -->
  </div>
</template>

<script>
export default {
  name: "Headers",
  data() {
    return {
      companyName: null,
      companyEmail: null,
      companyPassword: null,
      toggledClass: false,
      headerImages: {
        Image1: require("@/assets/img/bg12.jpg"),
        Image2: require("@/assets/img/examples/office2.jpg"),
        Image3: require("@/assets/img/dg1.jpg"),
        Image4: require("@/assets/img/dg2.jpg"),
        Image5: require("@/assets/img/favicon.png"),
      },
    };
  },
  methods: {
    header(nr) {
      return {
        backgroundImage: `url(${this.headerImages[`Image${nr}`]})`,
      };
    },
    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");

      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);

        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
  },
};
</script>

<style lang="scss">
.buttons {
  display: flex;
  align-items: center;
}

#headers {
  .el-carousel__container {
    height: 100vh !important;
  }
  .el-carousel {
    border-radius: 0 !important;
  }
}

.text-right {
  text-align: right;
}

.border-radius-0 {
  border-radius: 0;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}

@media all and (max-width: 960px) {
  .md-card-form-horizontal .md-layout-item {
    margin-top: 1.5rem;
  }
}

.cssanimation,
.cssanimation span {
  animation-duration: 1.5s;
  animation-fill-mode: both;
}

.cssanimation span {
  display: inline-block;
}

.fadeInBottom {
  animation-name: fadeInBottom;
}
@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}
</style>
