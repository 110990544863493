<template>
  <div class="wrapper">
    <div id="main-panel" class="main">
      <div id="blogs" class="cd-section">
        <blogs />
      </div>
    </div>
  </div>
</template>
<script>
import Blogs from "./Blogs";

import Mixins from "@/plugins/basicMixins";

export default {
  components: {
    Blogs,
  },
  mixins: [Mixins.VerticalNav],
};
</script>

<style lang="scss">
.vertical-nav-active {
  display: block;
}

@media all and (max-width: 768px) {
  .vertical-nav-active {
    display: none;
  }
}
</style>
