<template>
  <div class="wrapper">
    <!-- Features 1 -->
    <div class="section section-features-1">
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h2 class="title">Support</h2>
            <h5 class="description">Support Exchange ชั้นนำของโลก</h5>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-50 md-small-size-50">
            <img
              src="https://www.matichon.co.th/wp-content/uploads/2021/11/ogImage1.jpg"
              style="
                width: 90%;
                border-style: solid;
                border-width: medium;
                border-radius: 10px;
              "
              alt=""
            />
          </div>
          <div class="md-layout-item md-size-50 md-small-size-50">
            <img
              src="https://images.workpointnews.com/workpointnews/2021/05/20205600/1621518944_38591_web_bizview3.jpg"
              style="
                width: 90%;
                border-style: solid;
                border-radius: 10px;
                border-radius: 20px;
              "
              alt=""
            />
          </div>
          <div class="md-layout-item md-size-50 md-small-size-50">
            <img
              src="https://blockspaper-prod.oss-accelerate.aliyuncs.com/2020022646a0d16ea87d972f.jpg"
              style="
                width: 90%;
                border-style: solid;
                border-width: medium;
                border-radius: 10px;
                margin-top: 10px;
              "
              alt=""
            />
          </div>
          <div class="md-layout-item md-size-50 md-small-size-50">
            <img
              src="https://bitcoinaddict.org/wp-content/uploads/2019/12/poloniex-logo-large.png"
              style="
                width: 90%;
                border-style: solid;
                border-width: medium;
                border-radius: 10px;
                margin-top: 10px;
              "
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- end Features 1 -->

    <!-- end Features 5 -->
  </div>
</template>

<script>
export default {
  name: "supports",
  components: {},
  data() {
    return {
      iphone: require("@/assets/img/sections/iphone.jpg"),
      iphone2: require("@/assets/img/sections/iphone2.png"),
      image: require("@/assets/img/bg9.jpg"),
    };
  },
  computed: {
    features5() {
      return {
        backgroundImage: `url(${this.image})`,
      };
    },
  },
};
</script>

<style lang="scss"></style>
