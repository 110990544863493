<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter clear-filter"
      parallax-active="false"
      :style="headerStyle"
    >
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="image-wrapper">
            <div class="brand">
              <h1>Auto Rebalance</h1>
              <h2 class="title">บอทเทรดของนักเทรดมืออาชีพ</h2>
            </div>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <firstComponent />

      <components />
    </div>
  </div>
</template>

<script>
import firstComponent from "./presentation/firstComponent.vue";
import Components from "./presentation/components.vue";

import Mixins from "@/plugins/basicMixins";

export default {
  components: {
    Components,
    firstComponent,
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "presentation-page",

  data() {
    return {
      image: require("@/assets/img/002.jpg"),
      leafShow: false,
    };
  },
  mounted() {
    this.leafActive();
    window.addEventListener("resize", this.leafActive);
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.leafActive);
    document.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
    leafActive() {
      if (window.innerWidth < 768) {
        this.leafShow = false;
      } else {
        this.leafShow = true;
      }
    },
  },
};
</script>

<style lang="scss"></style>
