<template>
  <div class="section section-components pt-0">
    <div class="container">
      <div class="md-layout">
        <div class="md-layout-item md-size-90 md-small-size-100">
          <h3 class="title">Auto Rebalance</h3>
          <h5 class="description">
            ในโลกของการลงทุนการ Rebalancing
            หมายถึงกระบวนการปรับเปลี่ยนสินทรัพย์ในพอร์ตการลงทุนเพื่อรักษาระดับความเสี่ยงและการจัดสรรสินทรัพย์ที่ต้องการ
            โดยการ Rebalancing
            จะช่วยให้นักลงทุนสามารถบรรลุเป้าหมายการลงทุนโดยรวมได้ในระยะยาว การ
            Rebalancing พอร์ตลงทุนให้รองรับทุกสภาวะตลาด
            และผ่านการทดสอบของหลายช่วงเวลาซึ่งเป็นองค์ประกอบหลักของแผนการลงทุนระยะยาวของนักลงทุน
            อย่างไรก็ตามโดยทั่วไปกลยุทธ์นี้ขึ้นอยู่กับมูลค่าของพอร์ตลงทุนที่สูงกว่ามีโอกาสทำกำไรได้มากกว่า
            ซึ่งนักลงทุนจำนวนมากไม่แน่ใจในตรรกะพื้นฐานและกระบวนการคิดที่อยู่เบื้องหลังของกลยุทธ์
            Rebalancing และปฏิเสธที่จะเข้าใจและศึกษามันอย่างจริงจัง
            เนื่องจากนักลงทุนมักมองผลตอบแทนระยะสั้นเป็นหลัก
            มากกว่าการตระหนักถึงความเสี่ยงในการลงทุน เป้าหมายคือ
            การเพิ่มผลตอบแทน ลดความเสี่ยง หรืออาจจะรวมทั้งสองอย่างเข้าด้วยกัน?
          </h5>
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
          <div class="image-container">
            <img
              class="components-macbook"
              src="https://img.freepik.com/free-vector/illustration-law-concept_53876-28573.jpg?t=st=1646654275~exp=1646654875~hmac=78ca8815b87cdc4e87c3beac767a1974bd5018f3aff0f8325c028a3e0a6cd3ac&w=1380"
              alt=""
            />
          </div>
        </div>
        <div class="md-layout-item md-size-100 md-small-size-100">
          <h3>เมื่อใดจึงควร Rebalancing?</h3>
          <h5>
            แม้ว่าคำตอบอาจไม่ชัดเจนในทันที
            แต่ในส่วนนี้เราหวังว่าจะชี้แจงประเด็นสำคัญหลายประการว่าเหตุใดการ
            Rebalancing จึงเป็นสิ่งสำคัญของการวางแผนทางการเงินของพอร์ตลงทุนใดๆ
          </h5>
          <h3>มีเหตุผลสองประการที่ทำให้การ Rebalancing มีความสำคัญ</h3>
          <h4>1. การ Rebalancing พอร์ตการลงทุนของคุณช่วยจัดการความเสี่ยง</h4>
          <h5>
            หลักการสำคัญประการหนึ่งที่อยู่เบื้องหลังการจัดสรรสินทรัพย์คือแนวคิดที่ว่าสินทรัพย์บางประเภทไม่ได้เคลื่อนไหวไปในทิศทางเดียวกันในเวลาเดียวกัน
            พอร์ตการลงทุนที่มีความหลากหลายเป็นสิ่งสำคัญเนื่องจากช่วยลดความผันผวนตามเป้าหมายที่กำหนดไว้ล่วงหน้าของนักลงทุน
            อย่างไรก็ตามประสิทธิภาพที่ไม่สมมาตรของตำแหน่งในพอร์ตโฟลิโอยังนำไปสู่การเลื่อนการจัดสรรซึ่งจะทำให้โปรไฟล์ความเสี่ยงของพอร์ตโฟลิโอเปลี่ยนไปจากแผนกลยุทธ์
            ภาพล่างแสดงพอร์ตการลงทุนสองพอร์ตที่เริ่มต้นด้วย BTC และ USD 50/50
            ที่เรียบง่าย อย่างแรกจะถูกปรับสมดุลใหม่ทุก 10 วัน
            และอีกอย่างจะไม่ถูกปรับสมดุลในช่วง 10 ปี สิ่งนี้เน้นให้เห็นถึง“
            ผลที่ตามมา” ของการปล่อยให้พอร์ตโฟลิโอลอยไปโดยไม่ต้อง Rebalancing
            หากมีการกำหนดน้ำหนักเชิงกลยุทธ์แล้วเพิกเฉยต่อความเสี่ยงก็มีแนวโน้มที่จะเพิ่มขึ้นเมื่อเวลาผ่านไปเนื่องจากราคาบิทคอยมีความผันผวนสูงมาก
            ทำให้การจัดสรรพอร์ตการลงทุน มีความสำคัญอย่างยิ่งต่อการลงทุน
            ที่เราสามารถปรับพอร์ตโดยการนำ BTC แลกเปลี่ยนกับ USD
            ระหว่างทางซึ่งทำให้สามารถลดความเสี่ยงได้มากกว่า
            (ดังนั้นจึงได้ผลตอบแทนที่คาดหวังสูงกว่า) ถึงแม้การถือ BTC ไว้เฉยๆ
            อาจกำไรมากกว่า
            แน่นอนว่านี่ไม่ใช่ความแน่นอนเนื่องจากความเสี่ยงของพอร์ตการลงทุนอาจลดลงในช่วงเวลาที่กำหนด
            ในสถานการณ์ใดสถานการณ์หนึ่งความเสี่ยงของพอร์ตการลงทุนจะไม่สอดคล้องกับผลตอบแทนที่คาดหวัง
          </h5>
          <img
            src="https://www.wealthspire.com/wp-content/uploads/2020/11/Picture2.png"
            alt=""
          />

          <h5>
            การ Rebalancing ในทุกๆ ระดับราคาที่เปลี่ยนไป หรือการปรับพอทในทุกๆ
            วัน และการปรับพอททุกๆ การเปลี่ยนของยอดเงิน Balance
            ในปัจจุบันอาจเป็นทางเลือกในการวิเคราะห์การปรับสมดุลใหม่ของพอร์ต
            เพื่อช่วยลดความผันผวนของพอร์ตการลงทุน
            (ในรูปเป็นการวัดจากเส้นค่าเฉลี่ย)
            ขณะที่ผลตอบแทนอาจน้อยกว่าการถือครอง แต่ในระยะยาว จำนวน BTC และ USD
            อาจมากกว่าการลงทุนที่ปล่อยวางไว้เฉยๆ
            สถานการณ์ที่เลือกด้านล่างนี้เป็นเพียงตัวอย่างเท่านั้นและไม่รวมถึงต้นทุนบางประการในโลกแห่งความจริง
            เช่น ต้นทุนการทำธุรกรรม ภาษี และค่าธรรมเนียมการจัดการ ฯลฯ
            นอกจากนี้เรายังเชื่อมั่นในการติดตามโอกาสในการ Rebalancing
            พอร์ตการลงทุนอย่างต่อเนื่อง (เมื่อเทียบกับการประเมินตามระยะเวลา)
          </h5>
          <h4>2. การ Rebalancing ช่วยหลีกเลี่ยงการตัดสินใจลงทุนทางอารมณ์</h4>
          <h5>
            ตลอดช่วงชีวิตของนักลงทุนย่อมมีช่วงเวลาที่ผันผวนเพิ่มขึ้นหลายช่วงเวลา
            ไม่ว่าสิ่งนี้จะมาในรูปแบบของภาวะตลาดช็อกในระยะสั้น
            หรือภาวะถดถอยที่ยืดเยื้อมานาน (หรือแม้กระทั่งภาวะตลาดกระทิง)
            บ่อยครั้งที่อารมณ์บั่นทอนความเป็นเหตุเป็นผลและอาจทำให้นักลงทุนหันเหออกจากเป้าหมายระยะยาวในช่วงเวลาที่เลวร้ายที่สุด
            ซึ่งจะรวมถึงการลดการจัดสรรให้กับสินทรัพย์เสี่ยงในตลาด
            หลังจากการเทขายสูงลิ่วหรือการจัดสรรมากเกินไปหลังจากมูลค่าที่เพิ่มขึ้นอย่างมีนัยสำคัญ
            ตัวอย่างเช่น
            เราไฮไลต์ช่วงเวลาสองช่วงด้านล่างที่ตลาดปรับตัวขึ้นอย่างต่อเนื่องก่อนที่จะลดลงอย่างมาก
            ในตัวอย่างเหล่านี้เราจะเห็นว่าพอร์ตการลงทุนที่ไม่มีการปรับสมดุลจะให้ผลตอบแทนที่ดีกว่าในขณะที่หุ้นปรับตัวสูงขึ้นอย่างไรก็ตามพอร์ตการลงทุนจะประสบปัญหาการขาดทุนที่รุนแรงมากขึ้นในช่วงตลาดขาลง
            การ Rebalancing จะสามารถเปลี่ยนวิกฤตให้เป็นกำไรได้มากกว่าในระยะยาว
          </h5>
          <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
            <img
              src="https://www.wealthspire.com/wp-content/uploads/2020/11/Picture4.png"
              alt=""
            />
          </div>

          <h5>
            เราจะเห็นได้ว่าในช่วงปี 2008 พอร์ตการลงทุนที่ไม่ได้รับการ
            Rebalancing ได้รับความเสียหายอย่างรุนแรงซึ่งกวาดล้างกำไรของปีก่อนๆ
            ไป
            นักลงทุนอาจถูกบังคับให้สูญเสียมูลค่าพอร์ตการลงทุนที่มากขึ้นในเวลานั้นเนื่องจากพวกเขาไม่ได้รับการ
            Rebalancing
            ต้องใช้ระยะเวลารอคอยราคาปรับตัวสูงขึ้นจึงจะได้ผลกำไรที่เสียไปกลับคืนมา
          </h5>
          <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
            <img
              src="https://www.wealthspire.com/wp-content/uploads/2020/11/Picture5.png"
              alt=""
            />
          </div>
          <h5>
            เราเห็นสิ่งนี้อีกครั้งในช่วงที่ตลาดขาขึ้นในเดือนมีนาคม คศ. 2020
            ในช่วง COVID-19 พอร์ตโฟลิโอที่ไม่ยึดติดกับแนวทางการ Rebalancing
            จะได้รับผลกระทบจากความผันผวนที่ลดลงอย่างมากในเดือนมีนาคมซึ่งทำให้นักลงทุนลงทุนได้ยากขึ้นมาก
            โชคดีที่มีการกลับตัว Rebound อย่างรวดเร็วในตลาดหุ้น
            มิฉะนั้นความผันผวนของขาลงจะเจ็บปวดมากกว่าที่นักลงทุนคาดหวัง
            ต้นทุนการทำธุรกรรม -
            การรักษาต้นทุนและค่าธรรมเนียมให้ต่ำเป็นหนึ่งในหลักการสำคัญของปรัชญาการลงทุนของเรา
            ด้วยเหตุนี้เราจึงต้องคำนึงถึงการหมุนเวียนเป็นอย่างมากเนื่องจากต้นทุนที่ชัดเจน
            (ค่าคอมมิชชั่น) และค่าใช้จ่ายที่ไม่สามารถหลีกเลี่ยงได้
            (สเปรดและผลกระทบต่อตลาด) ถึงแม้จะมีค่าคอมมิชชั่นที่ต่ำมาก
            แต่ก็จำเป็นต้องหลีกเลี่ยงพอร์ตการลงทุนที่มีการซื้อขายมากเกินไป
            ซึ่งอาจส่งผลเสียในระยะยาว
            เพื่อลดต้นทุนการทำธุรกรรมให้น้อยที่สุดเราสามารถเลือกที่จะปรับพอร์ตลงทุนในทุกการเพิ่มขึ้นหรือลดลง
            ในพอร์ตลงทุน ณ
            เวลานั้นเปลี่ยนแปลงไปตามมูลค่าโดยรวมของทุนทั้งหมดที่มีปัจจุบัน
            โดยให้โรบอทเทรด ทำหน้าที่ในการคำนวน เช่น เมื่อพอร์ตเปลี่ยนแปลงไป 10%
            จะทำการปรับพอร์ตลงทุนเป็นรอบๆ
            หากมีความผันผวนมากส่งผลดีต่อพอร์ตโดยรวม
            ซึ่งจะช่วยให้พอร์ตการลงทุนมีการปรับสมดุล“ ตามธรรมชาติ” โดยการ
            Rebalancing จะได้รับกระแสเงินสดที่เกิดจากการปรับพอร์ตลงทุนในระยะยาว
          </h5>
          <h4>
            โดยกลยุทธ์สำหรับการ Rebalancing มีด้วยกัน 5 กลยุทธ์ ที่ Auto
            Rebalance ได้ค้นคว้ามา ซึ่งในที่นี้ จะกล่าวถึงเพียง 2
            กลยุทธ์เท่านั้น
          </h4>
          <h4>
            Amount - กำหนดจากการที่มูลค่าที่เพิ่มขึ้นและลดลงในพอร์ต จะทำการ
            Rebalancing เป็นจำนวน
          </h4>
          <h5>
            “จุดเด่น” ของการ Rebalancing
            ประเภทนี้คือไม่ต้องคอยตรวจสอบโอกาสในพอร์ตการลงทุน พวกเขาได้รับการ
            Rebalancing โดยไม่คำนึงถึงราคา ณ ปัจจุบัน หรือการจัดสรรเชิงกลยุทธ์
            เทคนิคอื่นใดที่ทำให้ต้องพิจารณาในการเข้าซื้อขาย แต่เป็นการคำนวนจาก
            จำนวนเงินของพอร์ตเปลี่ยนแปลงไปตามจำนวนเงิน เช่น
            มูลค่าพอร์ตลงทุนอยุ่ที่ 10,000 USDT จะทำการ Rebalancing ทุกๆ $100
            กลยุทธ์นี้จะไม่สนใจว่าพอร์ตโตขึ้นเท่าไหร่
            แต่จะปรับพอร์ตทุกครั้งที่มูลค่าพอร์ตเปลี่ยนแปลงไปตามที่ตั้งค่าไว้เสมอ
          </h5>
          <h4>
            Percent - กำหนดจากการที่มูลค่าที่เพิ่มขึ้นและลดลงในพอร์ต จะทำการ
            Rebalancing เป็นระยะ
          </h4>
          <h5>
            “จุดเด่น” ของการ Rebalancing
            ประเภทนี้คือไม่ต้องคอยตรวจสอบโอกาสในพอร์ตการลงทุน พวกเขาได้รับการ
            Rebalancing โดยไม่คำนึงถึงราคา ณ ปัจจุบัน หรือการจัดสรรเชิงกลยุทธ์
            เทคนิคอื่นใดที่ทำให้ต้องพิจารณาในการเข้าซื้อขาย
            ในมุมมองของเราวิธีการนี้ให้ผลลัพธ์ที่ดีกว่ามากเนื่องจากความผันผวนของราคาระหว่างประเภทสินทรัพย์ที่ต่างกัน
            จึงทำกำไรจากการขึ้นลงของราคา
            โดยไม่จำเป็นต้องขึ้นอยู่กับรอเวลาเพียงอย่างเดียว
            ซึ่งการใช้วิธีนี้อย่างมีประสิทธิภาพจำเป็นต้องใช้ประโยชน์จากเทคโนโลยีที่ช่วยให้สามารถตรวจสอบได้อย่างต่อเนื่องในขณะที่ตัดสินใจ
            Rebalancing จึงเป็นที่มาของ “กลยุทธ์บริหารความเสี่ยงแห่งยุคดิจิตอล”
            หรือที่เรียกว่า “Auto Rebalance” น่าเสียดายที่ไม่มีเกณฑ์
            “เหมาะสมที่สุด” ที่สามารถใช้กับกลยุทธ์การ Rebalancing ได้
            เมื่อทำการตัดสินใจนี้เราต้องชั่งน้ำหนักความสมดุลที่ละเอียดอ่อนระหว่างการอนุญาตให้การจัดสรรผันผวนจาก
            2 กลยุทธ์ข้างต้น โดยการ "ให้กลยุทธ์ที่เราเลือกดำเนินการ"
            และต้นทุนการทำธุรกรรม ที่เกี่ยวข้องกับการ Rebalancing
            เพื่อเข้าจัดการกับพอร์ตลงทุน
          </h5>
          <h3>ประเด็นสุดท้าย</h3>
          <h4>
            นักลงทุนเป็นมนุษย์และมีแนวโน้มที่จะมีอคติทางอารมณ์และพฤติกรรม แผนการ
            Rebalancing
            นี้เหมาะสมช่วยให้มั่นใจได้ว่านักลงทุนจะอยู่ต่อไปและยังคงลงทุนตลอดวัฏจักรตลาดที่ผันผวนในตลาดคริปโตเคอเรนซี่
            เพื่อเป็นประโยชน์เพิ่มเติมอาจช่วยเพิ่มผลตอบแทนที่แน่นอนมากกว่า
            ในช่วงกว่า 5 ปีที่ผ่านมาของการให้คำปรึกษาลูกค้า
            เราพบความจริงที่ว่าลูกค้าไม่ได้เพิ่มโอกาสที่จะประสบความสำเร็จในการบรรลุเป้าหมายทางการเงินโดยรับความเสี่ยงที่สูงขึ้นในพอร์ตการลงทุนของตน
            จึงสามารถสรุปได้ว่า Auto Rebalance
            ถือเป็นหัวใจของกลยุทธ์บริหารความเสี่ยงที่ให้ผลตอบแทนในระยะยาวเป็นหลัก
            อาจมีผลตอบแทนที่รวดเร็วในช่วงที่ตลาดขาขึ้น
            ทำให้รู้สึกว่าพอร์ตที่ลงทุนไปนั้นกำไรค่อนข้างมาก
            แต่สิ่งนึงที่ควรระมัดระวังนั่นคือเมื่อราคาสินทรัพย์ที่ลดลง
            จะทำให้มูลค่าพอร์ตลดลง ตามไปด้วย
            แต่พอร์ตลงทุนยังคงมีสภาพคล่องอยู่เสมอ
            เพื่อใช้เงินในยามฉุกเฉินหรือเมื่อเกิดวิกฤตต่างๆ
            โดยไม่ต้องขายสินทรัพย์บางส่วนออกไปโดยไม่จำเป็น
            และเป็นการใช้โรบอทในการปรับเปลี่ยนสินทรัพย์ดิจิตอลในพอร์ตลงทุนคริปโตเคอเรนซี่
            เพื่อบริหารความเสี่ยงในยุคดิจิตอล
            และการรักษาเงินต้นให้เกิดมูลล่าที่สูงขึ้นในระยะยาว
          </h4>
          <h3>คำเตือน</h3>
          <h5>
            1. การลงทุนมีความเสี่ยง ผู้ลงทุนควรศึกษาข้อมูลก่อนการตัดสินใจลงทุน
            <br />
            2. Auto Rebalance เป็นเพียงซอฟแวร์ เพื่อบริการ Rebalance Port
            เท่านั้น มิใช่การชี้ชวนลงทุน โดยไม่รับฝากเงินทุนของผู้ลงทุน
            ไม่มีสัญญาจ่ายเงินปันผล ไม่การันตีรายได้
            ไม่มีภาระผูกพันในการชดเชยผลขาดทุน
            หรือขอรับผลกำไรที่เกิดขึ้นจากผู้ลงทุน หรือประโยชน์อื่นใด
            <br />
            3. ผลประกอบการในอดีต ไม่สามารถการันตีปัจจุบัน และอนาคตได้
            <br />
            4.
            ผู้ลงทุนควรศึกษาความเข้าใจเกี่ยวกับกลยุทธบริหารความเสี่ยงนี้ให้ถี่ถ้วนก่อนการตัดสินใจลงทุน
            5. เอกสารนี้จัดทำขึ้นในวันที่ 11/1/2021
          </h5>

          <h5>
            *** Auto Rebalance จัดทำขึ้นเพื่อเป็นเอกสารอ้างอิงทางวิชาการ
            และประโยชน์ในการศึกษาค้นคว้าเปรียบเทียบข้อมูลประกอบการตัดสินใจให้กับผู้ที่สนใจเท่านั้น
          </h5>
          <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
            <img
              src="https://www.engdict.com/data/dict/media/images_public/profi-00054184_normal.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Components",
  data() {
    return {
      sectionComponents: {
        img1: require("@/assets/img/section-components/laptop-basics.png"),
        img2: require("@/assets/img/section-components/table.jpg"),
        img3: require("@/assets/img/section-components/share-btn.jpg"),
        img4: require("@/assets/img/section-components/coloured-card-with-btn.jpg"),
        img5: require("@/assets/img/section-components/coloured-card.jpg"),
        img6: require("@/assets/img/section-components/social-row.jpg"),
        img7: require("@/assets/img/section-components/pin-btn.jpg"),
      },
    };
  },
};
</script>

<style lang="css"></style>
