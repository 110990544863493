<template>
  <div class="wrapper">
    <div
      class="section section-testimonials-1 section-image"
      :style="sectionTestimonials(testimonials1)"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-100 mx-auto text-center"
          >
            <h2 class="title">Review</h2>
            <h5 class="description">
              ความสำเร็จบางส่วนของเพื่อนๆผู้ใช้ Auto Rebalance
            </h5>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <testimonial-card>
              <md-icon slot="icon"> format_quote </md-icon>
              <template slot="cardContent">
                <iframe
                  width="950"
                  height="534"
                  src="https://www.youtube.com/embed/mzmI6QofeRE"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h5 class="card-description">
                  Bitดอย Chanel : EP3 ถอนกำไร 40,000 บาท มาซื้อกล้อง
                  "ยังเหลือกำไร 7xx,xxx บาท
                </h5>
              </template>
              <template slot="cardAction">
                <h6 class="card-category">@Bitดอย Chanel</h6>
                <div class="md-card-avatar">
                  <a href="javascript:void(0)">
                    <img
                      class="img"
                      src="https://yt3.ggpht.com/ytc/AKedOLRsxljjXmOuFhDkPdJjipyXjdis4-VqRmwn4SQW=s88-c-k-c0x00ffffff-no-rj"
                    />
                  </a>
                </div>
              </template>
            </testimonial-card>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <testimonial-card>
              <md-icon slot="icon"> format_quote </md-icon>
              <template slot="cardContent">
                <iframe
                  width="950"
                  height="534"
                  src="https://www.youtube.com/embed/nADdItG3ASU"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h5 class="card-description">
                  EP.2 Auto Rebalancing Robot on Binance กำไรวันเดียว 26,000
                  บาท(Clickbaitนะ)
                </h5>
              </template>
              <template slot="cardAction">
                <h6 class="card-category">@Game Academy</h6>
                <div class="md-card-avatar">
                  <a href="javascript:void(0)">
                    <img
                      class="img"
                      src="https://yt3.ggpht.com/ytc/AKedOLTA-YRww1sLQiAZXvqLVPI-awBctZCYCYZs4oVSwVo=s88-c-k-c0x00ffffff-no-rj"
                    />
                  </a>
                </div>
              </template>
            </testimonial-card>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <testimonial-card>
              <md-icon slot="icon"> format_quote </md-icon>
              <template slot="cardContent">
                <iframe
                  width="950"
                  height="534"
                  src="https://www.youtube.com/embed/c5p68IEvYB4"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h5 class="card-description">
                  วิธี Rebalance ในตลาด Crypto Currency #การลงทุนมีความเสี่ยง
                  ควรศึกษาข้อมูลก่อนตัดสินใจลงทุน
                </h5>
              </template>
              <template slot="cardAction">
                <h6 class="card-category">@เดียว Investor</h6>
                <div class="md-card-avatar">
                  <a href="javascript:void(0)">
                    <img
                      class="img"
                      src="https://yt3.ggpht.com/ytc/AKedOLTEthNruLbTuLZhBrzWX_eyPI5dBILv56xdMLX3=s88-c-k-c0x00ffffff-no-rj"
                    />
                  </a>
                </div>
              </template>
            </testimonial-card>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <testimonial-card>
              <md-icon slot="icon"> format_quote </md-icon>
              <template slot="cardContent">
                <iframe
                  width="950"
                  height="534"
                  src="https://www.youtube.com/embed/_dEmeO_5dbo"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h5 class="card-description">
                  Bitดอย Chanel : EP1 ไม่รู้เรื้อง Bitcoin แค่เดือนเดียว
                  ทำกำไรไปเกือบ 70,000 บาท
                </h5>
              </template>
              <template slot="cardAction">
                <h6 class="card-category">@Bitดอย Chanel</h6>
                <div class="md-card-avatar">
                  <a href="javascript:void(0)">
                    <img
                      class="img"
                      src="https://yt3.ggpht.com/ytc/AKedOLRsxljjXmOuFhDkPdJjipyXjdis4-VqRmwn4SQW=s88-c-k-c0x00ffffff-no-rj"
                    />
                  </a>
                </div>
              </template>
            </testimonial-card>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <testimonial-card>
              <md-icon slot="icon"> format_quote </md-icon>
              <template slot="cardContent">
                <iframe
                  width="950"
                  height="534"
                  src="https://www.youtube.com/embed/veNm6gpC5CE"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h5 class="card-description">
                  EP.1 Bitcoin Invest Research Rebalancing
                </h5>
              </template>
              <template slot="cardAction">
                <h6 class="card-category">@Game Academy</h6>
                <div class="md-card-avatar">
                  <a href="javascript:void(0)">
                    <img
                      class="img"
                      src="https://yt3.ggpht.com/ytc/AKedOLTA-YRww1sLQiAZXvqLVPI-awBctZCYCYZs4oVSwVo=s88-c-k-c0x00ffffff-no-rj"
                    />
                  </a>
                </div>
              </template>
            </testimonial-card>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <testimonial-card>
              <md-icon slot="icon"> format_quote </md-icon>
              <template slot="cardContent">
                <iframe
                  width="950"
                  height="585"
                  src="https://www.youtube.com/embed/LtHdQU9sGPY"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h5 class="card-description">
                  Auto Rebalance สุดยอดบอทบริหารความเสี่ยง รีวิว by คุณไก่
                  ออนไลน์
                </h5>
              </template>
              <template slot="cardAction">
                <h6 class="card-category">@คุณไก่ ออนไลน์</h6>
                <div class="md-card-avatar">
                  <a href="javascript:void(0)">
                    <img
                      class="img"
                      src="https://yt3.ggpht.com/ytc/AKedOLTzAJkUlIFExkswvhpOxQCq_3u7eL5Wg6bwB0o-9g=s88-c-k-c0x00ffffff-no-rj"
                    />
                  </a>
                </div>
              </template>
            </testimonial-card>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <testimonial-card>
              <md-icon slot="icon"> format_quote </md-icon>
              <template slot="cardContent">
                <iframe
                  width="950"
                  height="534"
                  src="https://www.youtube.com/embed/df5L5YbRS34"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h5 class="card-description">
                  Bitดอย Chanel : EP2 ถาม-ตอบ Q&A "คำถามที่หลายคนสงสัย
                  หากจะเริ่ม Rebalance"
                </h5>
              </template>
              <template slot="cardAction">
                <h6 class="card-category">@Bitดอย Chanel</h6>
                <div class="md-card-avatar">
                  <a href="javascript:void(0)">
                    <img
                      class="img"
                      src="https://yt3.ggpht.com/ytc/AKedOLRsxljjXmOuFhDkPdJjipyXjdis4-VqRmwn4SQW=s88-c-k-c0x00ffffff-no-rj"
                    />
                  </a>
                </div>
              </template>
            </testimonial-card>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <testimonial-card>
              <md-icon slot="icon"> format_quote </md-icon>
              <template slot="cardContent">
                <iframe
                  width="950"
                  height="534"
                  src="https://www.youtube.com/embed/0DIjEySLnTM"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h5 class="card-description">
                  Bitดอย Chanel : EP4 ราคา Bitcoin ร่วงหนักขนาดนี้ "Auto
                  Rebalance" ยังมีกำไรอยู่มั้ย ?
                </h5>
              </template>
              <template slot="cardAction">
                <h6 class="card-category">@Bitดอย Chanel</h6>
                <div class="md-card-avatar">
                  <a href="javascript:void(0)">
                    <img
                      class="img"
                      src="https://yt3.ggpht.com/ytc/AKedOLRsxljjXmOuFhDkPdJjipyXjdis4-VqRmwn4SQW=s88-c-k-c0x00ffffff-no-rj"
                    />
                  </a>
                </div>
              </template>
            </testimonial-card>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100"></div>
        </div>
      </div>
    </div>
    <!-- end Testimonials 1 -->

    <div class="section">
      <div class="container">
        <div
          class="section md-layout-item md-size-90 md-small-size-100 mx-auto"
        >
          <div
            class="md-layout-item md-small-size-100 mx-auto"
            style="margin-top: -100px"
          >
            <h4 class="title">คำเตือน:</h4>
            <p>
              “Autorebalanceไม่ใช่การชักชวนลงทุน
              หรือผู้ให้คำแนะนำการลงทุนและการใช้งานดังกล่าวมีความเสี่ยง
              เราไม่สามารถการันตีผลลัพธ์ได้ว่ากำไรหรือขาดทุนเหมือนตัวอย่างที่รีวิว”
              “ไม่แนะนำให้ใช้งานหากไม่ทำการศึกษาข้อมูล
              ข้อจำกัดและยอมรับเงื่อนไขการใช้งาน
              และยอมรับความเสี่ยงก่อนติดสินใจใช้งาน” “Autorebalance
              เป็นเพียงผู้ให้บริการ บอทเทรดเท่านั้น” “การลงทุนมีความเสี่ยง
              ผู้ลงทุนควรศึกษาข้อมูล และยอมรับความเสี่ยงก่อนตัดสินใจลงทุน”
              <br />
            </p>
          </div>
          <div class="md-layout">
            <div
              class="md-layout-item md-size-20 md-small-size-33"
              style="margin-top: 10px"
              v-for="data in imgReviews"
              :key="data"
            >
              <img class="img-raised rounded img-fluid" :src="data.image" />
            </div>
            <div style="margin-top: 30px">
              <a
                href="https://www.facebook.com/autorebalance/photos/?ref=page_internal"
                target="_blink"
                style="font-size: 20px; font-weight: 600"
              >
                Views more
              </a>
            </div>
          </div>
          <h4 class="title">รีวิวจากผู้ใช้งานจริง :</h4>

          <p>
            <a href="https://youtu.be/LtHdQU9sGPY" target="_blink"
              >https://youtu.be/LtHdQU9sGPY</a
            >
            <br />

            รีวิว ep3 จาก bitดอย นำกำไรไปซื้อกล้องถ่ายรูป
            <br />
            <a href="https://youtu.be/mzmI6QofeRE" target="_blink"
              >https://youtu.be/mzmI6QofeRE</a
            >
            <br />
            แนะนำกลยุทธเบื้องต้น
            <a href="https://youtu.be/veNm6gpC5CE" target="_blink"
              >https://youtu.be/veNm6gpC5CE</a
            >
            <br />
            แนะนำบอทที่ใช้กลยุทธนี้บริหารความเสี่ยง
            <a href="https://youtu.be/nADdItG3ASU" target="_blink"
              >https://youtu.be/nADdItG3ASU</a
            >

            <br />
            รีวิวep1 จากบิทดอย ด้วยกลยุทธautorebalance
            <br />
            <a href="https://youtu.be/_dEmeO_5dbo" target="_blink"
              >https://youtu.be/_dEmeO_5dbo</a
            >
            <br />
            รีวิวep2 จากบิทดอย พอทโตขึ้น
            <br />
            <a href="https://youtu.be/df5L5YbRS34" target="_blink"
              >https://youtu.be/df5L5YbRS34</a
            >
            <br />
            รีวิวจาก โค๊ชเดียว
            <br />
            <a href="https://youtu.be/c5p68IEvYB4" target="_blink"
              >https://youtu.be/c5p68IEvYB4</a
            >
            <br />
            รีวิวจาก
            <a href="cryptonews.in.th" target="_blink">cryptonews.in.th</a>
            <br />
            <a
              href="https://cryptonews.in.th/review-bot-trade-autorebalance/"
              target="_blink"
              >https://cryptonews.in.th/review-bot-trade-autorebalance/</a
            >
            <br />
            การใช้งานดังกล่าวมีความเสี่ยงเราไม่สามารถการันตีผลลัพธ์ได้ว่ากำไรหรือขาดทุนเหมือนตัวอย่างที่รีวิว
            <br />
            ___________________________________
            <br />
            ติดต่อสอบถามข้อมูลเพิ่มเติม FB : Auto Rebalance
            <br />
            เข้ากลุ่มศึกษาการทำงานAutoRebalance
            <a href="http://line.me/ti/g/fqQ5X1GmYP" target="_blink"
              >http://line.me/ti/g/fqQ5X1GmYP</a
            >
            <br />
            รวมช่องทางติดต่อเพจ
            <a href="https://www.facebook.com/autorebalance" target="_blink"
              >https://www.facebook.com/autorebalance</a
            >
            หมายเลขติดต่อ 089-1769303 เมล์ติดต่อ mail :
            aiautorebalance@gmail.com เว็บไซด์
            <a href="https://autorebalance.co/" target="_blink"
              >https://autorebalance.co/</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TestimonialCard } from "@/components";

export default {
  name: "Reviews",
  components: {
    TestimonialCard,
  },
  data() {
    return {
      testimonials1: require("@/assets/img/dg2.jpg"),
      cardBlogPost: {
        cardBlogPost1:
          "https://cryptonews.in.th/wp-content/uploads/2022/02/S__5226628.jpg",
      },
      cardBlogPost2: {
        cardBlogPost1:
          "https://cryptonews.in.th/wp-content/uploads/2021/03/1_A1x1XR7PJEhZiSqSNWxvvw.png",
      },
      cardBlogPost3: {
        cardBlogPost1:
          "https://scontent.fbkk2-4.fna.fbcdn.net/v/t39.30808-6/274545843_493958235592183_8891905078477806124_n.jpg?_nc_cat=101&ccb=1-5&_nc_sid=8bfeb9&_nc_eui2=AeFtwmjIM0s5aKuP3FP_EKWE9KvFs1u-Bsj0q8WzW74GyHof3DbJchYdAFLJSwOAt-0jBuSrShuHTLMHvmqHAnGz&_nc_ohc=Rc2aeFz0ZeEAX8GMXjR&tn=j0WnZqtCr_hzpKka&_nc_ht=scontent.fbkk2-4.fna&oh=00_AT_hRjG8shrQy4ER-lJWkKeH9omKC4oRT-YFgJqbFjAuog&oe=622664C0",
      },
      imgReviews: [
        {
          image: require("@/assets/img/reviews/1.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/2.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/3.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/4.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/5.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/6.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/7.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/8.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/9.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/10.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/11.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/12.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/13.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/14.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/15.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/16.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/17.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/18.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/19.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/20.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/21.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/22.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/23.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/24.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/25.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/26.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/27.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/28.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/29.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/30.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/31.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/32.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/33.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/34.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/35.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/36.jpeg"),
        },
      ],
    };
  },
  methods: {
    sectionTestimonials(image) {
      return {
        backgroundImage: `url(${image})`,
      };
    },
  },
};
</script>

<style lang="scss"></style>
