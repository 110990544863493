<template>
  <div class="section">
    <div class="container">
      <div class="md-layout">
        <div class="md-layout-item md-medium-size-66 md-small-size-100 mx-auto">
          <h4>
            Auto Rebalance หรือ
            การใช้โรบอทในการปรับเปลี่ยนสินทรัพย์ดิจิตอลในพอร์ตลงทุนคริปโตเคอเรนซี่
            เพื่อบริหารความเสี่ยงในยุคดิจิตอล
            และการรักษาเงินต้นให้เกิดมูลล่าที่สูงขึ้นในระยะยาว
            ซึ่งกลยุทธนี้สามารถนำไปใช้ในสินทรัพย์อื่นๆได้ แต่ด้วยสภาพคล่องสูง
            ความเสี่ยงสูง ความผันผวนสูง ระยะเวลาที่รวดเร็ว ผลตอบแทนที่สูง
            ค่าการจัดการต่ำ ตลาดที่เปิด 24 ชั่วโมง
            ทำให้ตลาดคริปโตเคอเรนซี่สามารถตอบโจทย์ได้ดีที่สุด สำหรับกลยุทธ์นี้
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "firstComponent",
};
</script>

<style lang="css"></style>
